import Fingerprint2 from 'fingerprintjs2'

setTimeout( () => {
    Fingerprint2.get( undefined, function ( components ) {
        var values = components.map( function ( component ) { return component.value } )
        var clientID = Fingerprint2.x64hash128( values.join(''), 31 );
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-149865785-1', {
            'storage': 'none',
            'clientId': clientID
        });
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
    })
}, 500 )