import './analytics';
import './lightbox';
import './home';
import './productions';
import './person';
import './video';
import './masonry';
import './nav';
import './image';
import './news-item';
import './people';

document.body.classList.add('ontouchstart' in window ? 'touch' : 'no-touch')