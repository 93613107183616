import sleep from './utils/sleep';

[...document.querySelectorAll('.people')].forEach(container => {
    console.log('hi');
    const filters = [...container.querySelectorAll('[data-filter-key]')];
    const people = [...container.querySelectorAll('.person')];
    const filter = { location: null };
    filters.forEach(link => {
        link.addEventListener('click', () => {
            const { filterKey: key, filterValue: value } = link.dataset;
            setFilter(key, filter[key] === value ? null : value);
        });
    })
    var setFilter = async (key, value) => {
        filters.forEach(link => {
            link.classList.toggle('tcblack', value === link.dataset.filterValue);
        })
        people.forEach(person => {
            Object.assign(person.style, {
                transform: "scale(0)",
                transformOrigin: "0 0",
                transition: "transform 350ms"
            });
        })
        await sleep(350);
        await Promise.all(people.map(async person => {
            const data = person.dataset[key];
            const match = !value || data.split(',').find(v => v.trim().toLowerCase() === value.toLowerCase());
            if (match) {
                Object.assign(person.style, {
                    display: '',
                    transform: "scale(0)",
                    transformOrigin: "100% 100%"
                })
                await sleep(100);
                Object.assign(person.style, {
                    transform: 'none'
                })
            } else {
                person.style.display = 'none';
            }
        }))
    }
})