import VimeoPlayer from '@vimeo/player';
import YoutubePlayer from 'youtube-player';
import urlParser from "js-video-url-parser/lib/base";
import "js-video-url-parser/lib/provider/vimeo";
import "js-video-url-parser/lib/provider/youtube";

var findParent = ( el, fn ) => {
    if ( fn( el ) ) return el;
    if ( !el.parentNode ) return false;
    return findParent( el.parentNode, fn );
}

var createYoutubePlayer = ( el, url ) => {
    var player = YoutubePlayer( el, {
        videoId: urlParser.parse( url ).id,
        playerVars: {
            playsinline: false,
            modestbranding: 1
        }
    })
    var thumb = findParent( el, e => e.classList.contains( 'home-thumbnail' ) );
    if ( thumb ) thumb.classList.add( 'home-thumbnail--youtube-playing' );
    return {
        play: () => player.playVideo(),
        pause: () => player.pauseVideo()
    };
}

var createVimeoPlayer = ( el, url ) => {
    var player = new VimeoPlayer( el, {
        id: urlParser.parse( url ).id,
        portrait: false,
        dnt: true,
        transparent: false,
        autopause: true,
        controls: false,
        playsinline: false
    })
    return {
        play: () => player.play(),
        pause: () => player.pause()
    };
}

var createPlayer = ( el, url ) => urlParser.parse( url ).provider === 'vimeo'
    ? createVimeoPlayer( el, url )
    : createYoutubePlayer( el, url );

[ ...document.querySelectorAll('.video') ].forEach( container => {
    var thumbnail = container.querySelector('.video__thumbnail');
    var playerEl = container.querySelector('.video__player');
    var player = null;
    var url = playerEl.dataset.videoUrl;
    var controls = container.querySelector('.video__controls');
    var fullscreenToggle = container.querySelector('.video__fullscreen');
    var playing = false;
    var fullscreen = false;
    var play = () => {
        player.play();
        playing = true;
        controls.innerText = 'pause';
    }
    var pause = () => {
        player.pause();
        playing = false;
        controls.innerText = 'play';
    }
    if ( urlParser.parse( url ).provider === 'vimeo' ) {
        controls.addEventListener( 'click', () => playing ? pause() : play() )
        fullscreenToggle.addEventListener( 'click', () => {
            if ( !fullscreen ) {
                container.requestFullscreen();
                fullscreen = true;
            } else {
                document.exitFullscreen();
                fullscreen = false;
            }
        });
    } else {
        controls.style.display = fullscreenToggle.style.display = 'none'
    }
    thumbnail.addEventListener( 'click', () => {
        player = createPlayer( playerEl, url );
        thumbnail.parentNode.removeChild( thumbnail );
        play();
    })
})