import range from 'lodash/range';
import { rect, line } from './utils/dom';
import { box } from './utils/triangle';

// var map = ( x, oldMin, oldMax, newMin, newMax ) => 
//     newMin + ( x - oldMin ) / ( oldMax - oldMin ) * ( newMax - newMin );

var findMin = ( arr, fn ) => {
    var mins = arr.map( fn );
    return arr[ mins.indexOf( Math.min( ...mins ) ) ];
};

var clone = el => {
    var copy = el.cloneNode( true );
    el.parentNode.appendChild( copy );
    return copy;
}

[ ...document.querySelectorAll( '.home' ) ].forEach( container => {

    var spacer = container.querySelector('.home__thumbnails');
    var [ trailer, ...thumbnailEls ] = [ ...container.querySelectorAll( '.home-thumbnail' ) ];
    thumbnailEls = [ ...thumbnailEls, ...thumbnailEls.map( clone ) ];
    var thumbnails = thumbnailEls.map( thumb => ({
        el: thumb,
        image: thumb.querySelector( '.home-thumbnail__image' ),
        textRight: thumb.querySelector( '.home-thumbnail__text-right' ),
        textOver: thumb.querySelector( '.home-thumbnail__text-over' )
    }));
    var newsItems = [ ...container.querySelectorAll( '.home__news-item' ) ];
    var nav = document.querySelector( 'nav' );
    var footer = container.querySelector('.home__footer');

    var containerRect = () => {
        var top = rect( nav ).height;
        var { left, right, width } = rect( container );
        var footerHeight = rect( footer ).height
        return {
            top,
            left,
            bottom: window.innerHeight - footerHeight,
            right,
            width,
            height: window.innerHeight - ( top + footerHeight )
        }
    }

    var getRange = () => {
        var rows = window.innerWidth > window.innerHeight ? 3 : 4;
        var overflow = window.innerWidth > window.innerHeight ? 1 : 3;
        var trailerSpan = findMin( range( rows ), i => {
            var { width, height } = box( 0, i, rows, overflow, containerRect() );
            return Math.abs( height/width - 9/16 )
        });
        return { rows, overflow, trailerSpan };
    }

    var render = () => {

        var { rows, overflow, trailerSpan } = getRange();
        var containerHeight = containerRect().height;
        var interval = containerHeight / rows;
        var st = window.pageYOffset / interval;
        spacer.style.height = interval * ( ( thumbnails.length / 2 ) + rows + 1 + trailerSpan ) + 'px';

        var row = ( t, span = 1 ) => box( t, span, rows, overflow, containerRect() );
        var thumbnailWidth = ( t, span = 1 ) => {
            var row0 = row( 0, span );
            var maxThumbnailWidth = Math.min( row0.height * 16/9, row0.width );
            return maxThumbnailWidth * ( row( t, span ).height / row0.height );
        }
        
        var containerWidth = containerRect().width;
        var minTextWidth = line() * 10;
        var newsRowSpace = containerWidth - thumbnailWidth( rows - ( newsItems.length + 1 ) );
        var showNews = newsRowSpace >= minTextWidth;
        var showText = showNews
            ? ( newsRowSpace - minTextWidth ) >= minTextWidth
            : ( containerWidth - thumbnailWidth( 0 ) ) >= minTextWidth;
        var newsWidth = showText ? newsRowSpace / 2 : newsRowSpace;
        var textWidth = showText 
            ? showNews ? newsWidth : containerWidth - thumbnailWidth( 0 )
            : 0

        var trailerBase = row( 0, trailerSpan );
        var trailerBox = row( -st, trailerSpan );
        Object.assign( trailer.style, {
            width: trailerBase.width + 'px',
            height: trailerBase.height + 'px',
            transform: `
                translate( ${ trailerBox.left }px, ${ trailerBox.bottom - trailerBase.height }px )
            `
        });
        
        thumbnails.forEach( ({ el, image, textRight }, i ) => {
            var t = ( i + trailerSpan ) - st;
            var { top, left, bottom, height } = box( t, 1, rows, overflow, containerRect() );
            var scale = Math.max( height / row( 0 ).height, 0 );
            
            Object.assign( image.style, {
                width: thumbnailWidth( 0 ) + 'px',
                height: row( 0 ).height + 'px',
                transform: `
                    translate( ${ left }px, ${ top }px )
                    scale( ${ scale } )
                `
            });
            image.setAttribute( 'sizes', row( 0 ).height * 16/9 + 'px' );
            el.classList.toggle( 'home-thumbnail--show-text-over', !showText )

            Object.assign( textRight.style, {
                display: showText ? '' : 'none',
                width: textWidth,
                opacity: height > rect( textRight ).height ? 1 : 0,
                transition: 'opacity .2s',
                transform: `
                    translate( ${ left + thumbnailWidth( 0 ) * scale }px, ${ bottom }px )
                    translateY( -100% )
                `
            })
            if ( st > ( ( thumbnails.length / 2 ) + trailerSpan ) ) {
                window.scrollTo( 0, ( st - ( thumbnails.length / 2 ) ) * interval );
            }

        })

        newsItems.forEach( ( item, i ) => {
            var t = ( i + rows ) - Math.min( st, 2 );
            var { left: rowLeft, bottom, height } = row( t )
            var left = rowLeft + thumbnailWidth( t - 1 ) + ( showText ? textWidth : 0 );
            Object.assign( item.style, {
                display: showNews ? '' : 'none',
                opacity: height > rect( item ).height ? 1 : 0,
                transition: 'opacity .2s',
                top: 0,
                left: 0,
                width: showNews ? newsWidth + 'px' : '',
                transform: !showNews
                    ? ''
                    : `
                        translate( ${ left }px, ${ bottom }px )
                        translateY( -100% )
                    `
            })
        })
    }

    window.addEventListener( 'resize', render );
    window.addEventListener( 'scroll', render );
    render();
})