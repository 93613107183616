[ ...document.querySelectorAll('.person') ].forEach( el => {
    var text = el.querySelector( '.person__text' );
    var update = () => {
        var height = el.getBoundingClientRect().height;
        var scale = (
            ( height - text.getBoundingClientRect().height ) /
            height
        );
        el.style.setProperty('--hover-scale', scale )
    }
    window.addEventListener( 'resize', update );
    update();
})