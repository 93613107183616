import { rect } from './utils/dom'

[ ...document.querySelectorAll('.home__news-item') ].forEach( container => {
    if ( 'ontouchstart' in window ) return;
    var title = container.querySelector( '.home__news-item-title' );
    var excerpt = container.querySelector( '.home__news-item-excerpt' );
    container.addEventListener('mouseenter', () => {
        excerpt.style.opacity = 1;
        title.style.transform = `translateY( ${ -rect( excerpt ).height }px)`
    })
    container.addEventListener('mouseleave', () => {
        excerpt.style.opacity = '';
        title.style.transform = '';
    })
})