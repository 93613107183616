import memoize from 'lodash/memoize';
import debounce from 'lodash/debounce';

export var measure = fn => {
    var memoized = memoize( fn );
    window.addEventListener( 'resize', () => memoized.cache = new memoize.Cache )
    return memoized;
}

export var cssVar = prop => {
    var style = window.getComputedStyle( document.documentElement );
    return style.getPropertyValue( prop );
}

export var rem = measure( () => parseFloat( cssVar( '--rem' ) ) );

export var line = measure( () => parseFloat( cssVar( '--line-height' ) ) * rem() );

export var rect = measure( el => el.getBoundingClientRect() );

var mutations = new Map();
var flush = debounce( () => mutations.forEach( ( fn, key ) => {
    fn();
    mutations.delete( key );
}), 500 )
export var mutateDebounced = ( key, fn ) => {
    mutations.set( key, fn );
    flush();
}

export var parseSrcset = srcset => srcset
    .split(',')
    .map( size => {
        var [ url, w ] = size.split(' ');
        return { url: url.trim(), w: Number( w.slice( 0, -1 ) ) }
    })