import { line } from './dom';

export var triangle = n => Math.max( ( n * ( n + 1 ) ) / 2, 0 );

export var sample = ( t, visbleRows, extraRows = 0 ) => {
    var totalRows = visbleRows + extraRows;
    var totalUnits = triangle( totalRows );
    var visibleUnits = totalUnits - triangle( extraRows );
    return ( totalUnits - triangle( Math.max( totalRows - t, 0 ) ) ) / visibleUnits;
};

export var box = ( t, rowSpan, visibleRows, extraRows, containerRect ) => {
    var top = containerRect.top + sample( t, visibleRows, extraRows ) * containerRect.height;
    var bottom = containerRect.top + sample( t + rowSpan, visibleRows, extraRows ) * containerRect.height - line();
    return {
        top,
        left: containerRect.left,
        bottom,
        right: containerRect.right,
        width: containerRect.width,
        height: bottom - top
    }
}