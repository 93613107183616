import { value, tween, easing } from 'popmotion';
import { rect, line } from './utils/dom';
import { box } from './utils/triangle';
import sleep from './utils/sleep';
import throttle from 'lodash/throttle';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';

var match = (thumbnails, filter) => {
    var matched = [];
    var unmatched = [];
    thumbnails.forEach(thumbnail => {
        const matchesTag = filter.tag === null || thumbnail.tag === filter.tag;
        const matchesLocation = filter.location === null || thumbnail.location.includes(filter.location);
        if (matchesTag && matchesLocation) {
            matched.push(thumbnail);
        } else {
            unmatched.push(thumbnail);
        }
    });
    return [matched, unmatched];
}

var frameThrottle = fn => {
    var queued = false;
    return () => {
        if (queued) return;
        queued = true;
        requestAnimationFrame(() => {
            queued = false;
            fn();
        })
    }
}

var drawImageCover = (ctx, image, x, y, w, h) => {
    var scale = Math.max(w / image.width, h / image.height);
    var sWidth = w / scale;
    var sHeight = h / scale;
    var sx = (image.width - sWidth) / 2;
    var sy = (image.height - sHeight) / 2;
    ctx.drawImage(image, sx, sy, sWidth, sHeight, x, y, w, h);
}

var statusText = status => {
    if (status === 'inDevelopment') return ' (in development)'
    if (status === 'inProduction') return ' (in production)'
    return '';
}

[...document.querySelectorAll('.productions')].forEach(container => {

    var data = JSON.parse(container.querySelector('script').innerText);
    var productions = data.map(production => {
        var placeholder = new Image();
        placeholder.src = production.placeholderUrl;
        var thumbnail = new Image();
        thumbnail.srcset = production.srcset;
        return { ...production, thumbnail, placeholder, quality: 1 };
    })
    var productionsByID = keyBy(productions, p => p.id);

    var filter = { tag: null, location: null };
    var filters = [...document.querySelectorAll('[data-filter-key]')]
    var filterTransitionState = value(0);
    var setFilter = async (key, value) => {
        if (filter[key] === value) return;
        tween({
            to: 1,
            duration: 350,
            ease: easing.easeIn
        }).start(filterTransitionState)
        await sleep(500)
        filter[key] = value;
        window.scrollTo({ top: 0 });
        setContainerHeight()
        filters.forEach(link => {
            link.classList.toggle('tcblack', filter[link.dataset.filterKey] === link.dataset.filterValue);
        })
        tween({
            from: 1,
            to: -1,
            duration: 0,
            ease: easing.easeOut
        }).start(filterTransitionState)
        tween({
            from: -1,
            to: 0,
            duration: 350
        }).start(filterTransitionState)
        await sleep(500)
    }
    filters.forEach(link => {
        link.addEventListener('click', () => {
            const { filterKey: key, filterValue: value } = link.dataset;
            setFilter(key, filter[key] === value ? null : value);
        });
    })

    var nav = document.querySelector('nav');
    var footer = container.querySelector('.productions__footer');
    var containerRect = () => {
        var headerHeight = rect(nav).height;
        var footerHeight = rect(footer).height;
        var { left, right, width } = rect(container);
        return {
            top: headerHeight,
            left,
            bottom: window.innerHeight - footerHeight,
            right,
            width,
            height: window.innerHeight - (headerHeight + footerHeight)
        }
    }

    var getLayout = () => {
        var numRows = Math.floor(window.innerHeight / window.innerWidth * 3) + 2
        var extraRows = 5;
        var row1 = box(0, 1, numRows, extraRows, containerRect());
        var thumbnailTargetWidth = row1.height * 16 / 9;
        var perRow = Math.max(Math.round(row1.width / (thumbnailTargetWidth + line())), 1)
        var thumbnailWidth = (row1.width - line() * (perRow - 1)) / perRow;
        var thumbnailHeight = row1.height;
        return { numRows, extraRows, perRow, thumbnailWidth, thumbnailHeight };
    }

    var setContainerHeight = () => {
        var { perRow, numRows, thumbnailHeight } = getLayout();
        var [matched] = match(productions, filter);
        container.style.height = thumbnailHeight * (matched.length / perRow + numRows - 1) + 'px';
    }

    var getRects = () => {

        var { numRows, extraRows, perRow, thumbnailWidth, thumbnailHeight } = getLayout();
        var st = window.pageYOffset / thumbnailHeight;
        var [matched] = match(productions, filter);
        return matched.map((production, i) => {
            var column = i % perRow;
            var row = Math.floor(i / perRow);
            var t = row - st;
            var { top, left: rowLeft, height } = box(t, 1, numRows, extraRows, containerRect());
            var scale = height / thumbnailHeight;
            var width = thumbnailWidth * scale;
            var left = rowLeft + (width + line()) * column;
            return {
                production,
                top, left, width, height,
                right: left + width, bottom: top + height
            }
        }).filter(rect => {
            return rect.bottom > 0 && rect.top < window.innerHeight
        })
    }

    var links = {};
    var createLink = production => {
        var el = document.createElement('h3');
        el.className = "psf t0 l0 p0-5 ttl wsnw oh toe tcwhite pen"
        var title = document.createElement('span');
        var status = document.createElement('span');
        status.className = "fade op0"
        el.appendChild(title);
        el.appendChild(status);
        title.innerHTML = production.title;
        status.innerHTML = statusText(production.status);
        var link = {
            el,
            setPosition: (x, y) => {
                el.style.transform = `
                    translate3d( ${ x}px, ${y}px, 0 )
                    translateY( -100% )
                `
            },
            setWidth: throttle(w => el.style.width = w + 'px', 1000),
            setHover: v => status.classList.toggle('op0', !v)
        };
        return link
    }

    var rects = [];
    var canvas = container.querySelector('canvas');
    var ctx = canvas.getContext('2d');
    var dummyLink = createLink({ title: "&nbsp;", status: "" })
    container.appendChild(dummyLink.el)

    var hitTest = (x, y) => rects.find(rect => (
        rect.left < x &&
        rect.right > x &&
        rect.top < y &&
        rect.bottom > y
    ))

    var hovered = null;
    window.addEventListener('mousemove', e => {
        if (hovered) links[hovered.production.id].setHover(false);
        hovered = hitTest(e.clientX, e.clientY);
        canvas.classList.toggle('curp', !!hovered);
        if (hovered) links[hovered.production.id].setHover(true);
    })
    canvas.addEventListener('click', e => {
        var hovered = hitTest(e.clientX, e.clientY);
        if (hovered) location.href = hovered.production.url;
    })

    var render = frameThrottle(() => {
        console.log(filter);
        var nextRects = getRects();
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var transition = filterTransitionState.get();

        nextRects.forEach(rect => {
            var scale = 1 - Math.abs(transition);
            var x = rect.left + rect.width * Math.max(transition, 0);
            var y = rect.top + rect.height * Math.max(-transition, 0);
            var w = rect.width * scale
            var h = rect.height * scale;
            var { placeholder, thumbnail, quality, srcset } = rect.production;
            ctx.globalAlpha = 1;
            ctx.drawImage(placeholder, x, y, w, h);
            if (quality > 0) {
                ctx.globalAlpha = 1;
                if (!thumbnail.srcset) thumbnail.srcset = srcset;
                drawImageCover(ctx, rect.production.thumbnail, x, y, w, h);
            }
        })
        var prevIDs = keyBy(rects, r => r.production.id);
        var nextIDs = keyBy(nextRects, r => r.production.id);
        var prevKeys = Object.keys(prevIDs);
        var nextKeys = Object.keys(nextIDs)
        var removed = difference(prevKeys, nextKeys);
        var added = difference(nextKeys, prevKeys);
        removed.forEach(id => {
            container.removeChild(links[id].el);
            delete links[id];
        })
        added.forEach(id => {
            var link = createLink(productionsByID[id])
            var rect = nextIDs[id];
            link.setPosition(rect.left, rect.bottom)
            container.appendChild(link.el);
            links[id] = link;
        })
        for (var id in links) {
            var link = links[id];
            var imageRect = nextIDs[id];
            link.setPosition(imageRect.left, imageRect.bottom)
            link.el.style.opacity = imageRect.height > rect(dummyLink.el).height ? 1 : 0;
            link.setWidth(imageRect.width);
        }
        rects = nextRects;

    })

    var resize = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        ctx.font = getComputedStyle(container).font;
        setContainerHeight();
    }
    resize();
    window.addEventListener('resize', resize);

    var tick = () => {
        render();
        requestAnimationFrame(tick);
    }
    tick()

})